import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { UserQuery } from '../services/user/user.query';
import {requestsService} from '../services/requests/requests.service'
@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnInit {

  username = null;
  password1="";
  verifyUser: FormGroup;
  orgId:any;
  pass:any
  constructor(
    private router: Router,
    private activeRouter: ActivatedRoute,private user1: UserQuery,private apiService: requestsService) {
    this.verifyUser = new FormGroup({
      code: new FormControl('', [Validators.required])
    });

    const user = this.activeRouter.snapshot.queryParams.user;
    const password=this.activeRouter.snapshot.queryParams.password;
    this.orgId=this.activeRouter.snapshot.queryParams.org
    console.log(user)
    console.log(password)
    if (user) {
      this.username = user;
      this.password1=password;
      this.pass=password
    } else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit(): void {
  }

  verify(event) {
    console.log(event)
    event.preventDefault();
    if (this.verifyUser.invalid) {
      return;
    }
    const code = this.verifyUser.value.code;
    console.log(code)
    Auth.confirmSignUp(this.username, code)
      .then(res => {
          console.log(res)
          Auth.signIn(this.username, this.password1).then((res1: any) => {
            console.log(res1)
            this.getcurrentCredential().then(token=>{
              var tempToken=token as string;
                this.user1.setToken(tempToken);
                this.user1.setUserName(this.username);
                this.user1.setPassword(this.password1);
                localStorage.setItem('UserCred', JSON.stringify({ Username: this.username, Password: this.password1 }));
                //this.router.navigateByUrl('/home');
                if(this.orgId!=undefined){
                  this.apiService.isValidInviteCheck({"memberEmail":this.username,"orgName":this.orgId}).subscribe(res=>{
                    if(res==true){
                      console.log("ragini")
                      Auth.currentAuthenticatedUser().then(user => {console.log(user)
                        Auth.updateUserAttributes(user,{"custom:organisation":this.orgId}).then(data=>{
                          console.log("set organisation",data)
                          if(data){
                            this.apiService.getUserdetails().subscribe(s=>{
                              console.log(s)
                              Auth.signIn(this.username, this.password1).then((res: any) => {
                                this.user1.setToken(res.signInUserSession.idToken.jwtToken);
                                if(res){
                                  this.apiService.userPlanDetails().subscribe(res=>{})
                                  this.router.navigateByUrl('/home');
                                }
                              })
                            });
                          }
                        })
                        })
                    }
                  })
                }else{
                  console.log("test")
                  this.apiService.getUserdetails().subscribe(s=>{
                    if(s){
                      this.apiService.userPlanDetails().subscribe(res=>{})
                      this.router.navigateByUrl('/home');
                    }
                  });
                }
            })
           
          }, err => {
           console.log(err)
          });
      }, err => {

      });
  }

  async getcurrentCredential() : Promise<string>{
    const result = await Auth.currentSession();
    console.log(result);
    console.log(result.getIdToken().getJwtToken());
    var IdToken=result.getIdToken().getJwtToken();
    return IdToken;
  }
}
