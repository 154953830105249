import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { requestsService } from 'src/app/services/requests/requests.service';
import { UserQuery } from 'src/app/services/user/user.query';
import { Auth } from 'aws-amplify';
@Component({
  selector: 'app-payment-redirect',
  templateUrl: './payment-redirect.component.html',
  styleUrls: ['./payment-redirect.component.scss']
})
export class PaymentRedirectComponent implements OnInit {
  paymentSuccess = false;
  loading = true;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private userQuery: UserQuery,
    private apiService: requestsService) { }

  ngOnInit(): void {
    const params = this.route.snapshot.queryParams;

    if (params && params.id && params.membershipId) {
      this.loading = false;
      this.paymentSuccess = true;
      this.apiService.savePaymentMethodForCustomer(params.id).
        pipe(
          switchMap(() => {
            console.log('success')
            return this.apiService.upgradePlan(params.membershipId);
          }),
          switchMap(() => {
            console.log('Upgradplan')
            return this.apiService.getUserdetails() && this.apiService.userPlanDetails();
          })
        )
        .subscribe(res => {
          if (res) {
            var usercred=JSON.parse(localStorage.getItem('UserCred'))
            const username =usercred.Username;
            const password = usercred.Password;
            console.log(username);
            console.log(password)
            Auth.signIn(username,password).then((res1: any) => {
              console.log(res1)
              this.getcurrentCredential().then(token=>{
                var tempToken=token as string;
                  this.userQuery.setToken(tempToken);
                  this.userQuery.setCurrentPlan(res);
                  this.userQuery.setUserName(username);
                  this.userQuery.setPassword(password);
                  this.refreshPage()
                  
              })
             
            })
            
          }
          this.router.navigateByUrl("/home/settings/order/orders");
        }, err => {
          this.paymentSuccess = false;
        });
    } else {
      this.loading = false;
    }

    if (params && !params.success) {
      this.router.navigateByUrl("/home/settings/order/orders");
    }
  }

   async getcurrentCredential() : Promise<string>{
    const result = await Auth.currentSession();
    console.log(result);
    console.log(result.getIdToken().getJwtToken());
    var IdToken=result.getIdToken().getJwtToken();
    return IdToken;
  }
  refreshPage() {
    window.location.reload();
   }
}
