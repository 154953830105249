import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { Auth } from 'aws-amplify';
import { switchMap ,map} from 'rxjs/operators';
import { Observable, interval, Subscription } from 'rxjs';
import { requestsService } from '../services/requests/requests.service';
import { UserQuery } from '../services/user/user.query';
import { DomSanitizer } from '@angular/platform-browser';
import {CookieService} from 'ngx-cookie'
import { ToastrService } from 'ngx-toastr';
import {GenesystokenService} from '../services/genesystoken.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('viewDOM') viewDOM: ElementRef;

  settingWidth:boolean=false
  user: any;
  thumbnail:any;
  availableFeatures = {
    AutomaticTesting: false,
    LoadTesting: false,
    ScheduledTesting: false
  }

  navList = [
    {
      name: 'Dashboard',
      icon: 'dashboard.svg',
      iconSelected: 'dashboard-light.svg',
      route: 'dashboard'
    },
    {
      name: 'Service',
      icon: 'service.svg',
      iconSelected: 'service-light.svg',
      route: 'service'
    },
    {
      name: 'Script',
      icon: 'script.svg',
      iconSelected: 'script-light.svg',
      route: 'script'
    },
    {
      name: 'Test Method',
      icon: 'testing.svg',
      iconSelected: 'testing-light.svg',
      route: 'test-method',
      children: [
        {
          name: 'Load Testing',
          route: 'load-testing',
          available: 'LoadTesting'
        },
        {
          name: 'Scheduled Testing',
          route: 'scheduled-testing',
          available: 'ScheduledTesting'
        },
        {
          name: 'Automated Testing',
          route: 'automated-testing',
          available: 'AutomaticTesting'
        },
        // {
        //   name: 'IVR Testing',
        //   route: 'ivr-testing'
        // }
      ]
    },
   
    {
      name: 'Reports',
      icon: 'reports.svg',
      iconSelected: 'reports-light.svg',
      route: 'reports',
      children: [
        // {
        //   name: 'Summary',
        //   route: 'summary'
        // },
        {
          name: 'High Level Report',
          route: 'high-level'
        },
        {
          name: 'Sprint Details Report',
          route: 'sprint'
        },
        {
          name: 'Invoice',
          route: 'invoice'
        },
        {
          name: 'Load Sprint Report',
          route: 'loadSprint'
        },
        {
          name: 'Script Folder Report',
          route: 'script-folder'
        },
        {
          name: 'Sprint Folder Report',
          route: 'sprint-folder'
        },
        {
          name: 'Audit logs',
          route: 'audit-log'
        },
        {
          name: 'SubOrg Report',
          route: 'getSubOrgReport'
        }
      ]
    },
    {
      name: 'Membership',
      icon: 'membership.svg',
      iconSelected: 'membership-light.svg',
      route: 'membership'
    },
    {
      name: 'Ivr Tree',
      iconSelected: 'membership-light.svg',
      icon: 'membership.svg',
      activeSectionName:"Ivr",
      route: 'Ivr',
     // display:true// check 
      
    }
  ];

  activePage = 'dashboard';
  activeSection = 'dashboard'

  showUserMenu = false;
  memberShipPlan:string='';
  platformclient:any;
  readAccess:boolean=false;
  writeAccess:boolean=false;
  fullAccess:boolean=false;
  lastloginTime:any;
  currentUrl:any;
  UserDataList:any
  constructor(
    private routerQuery: RouterQuery,
    private userQuery: UserQuery,
    private router: Router,
    private apiService: requestsService, private sanitizer: DomSanitizer,private cookie:CookieService,private toastr: ToastrService
    ,private geneSysService:GenesystokenService,private http: HttpClient) {

      if(this.geneSysService.tokenType=='aws'){
        this.userQuery.alreadyLogged().subscribe(user => {
          console.log(user)
          this.user = user;
        });
        if(this.userQuery.getSamlUser()=="SamlUser"){
          this.loadProfileImg();
        }
        this.apiService.getRoleListByUsername().subscribe(res=>{
          console.log(res)
          this.lastloginTime= this.userQuery.getLoginTime()
          console.log(this.lastloginTime)
          this.userQuery.setRole(res.role);
          this.userQuery.getRole().subscribe((user: any) => {
            console.log(user);
            if(user){
            this.readAccess=user.readAccess;
            this.writeAccess=user.writeAccess;
            this.fullAccess=user.fullAccess
            }
          });
        })
      
          Auth.currentUserInfo().then(res => {
            console.log(res)
            if (res && res.attributes) {
              this.userQuery.setUser(res.attributes);
              this.loadProfileImg();
            } else {
             // this.logout();
            }
          }, err => {
            this.logout();
          });
      }
      else{
        this.platformclient= (<any>window).platformClient;
        const usersApi = new this.platformclient.UsersApi();
        let opts = {'expand': ['authorization']};
        usersApi.getUsersMe(opts).then(res=>{
          this.user=res;
          this.geneSysService.setUser(res);
          this.loadProfileImg();
        }).catch((e) => {console.error(e);this.logout();}); 
   // return usersApi.getUsersMe(opts);
      }
   
  }
  public getTokenDetailsFromCognito(callbackCode: string): Observable<any> {
    const details = {
      grant_type: 'authorization_code',
      code: callbackCode,
      scope: 'openid+profile',
      redirect_uri: "https://demoquicktest.auth.eu-west-2.amazoncognito.com/login?client_id=l4cdqg69524auqlckjcifr44p&response_type=token&scope=email+openid+profile&redirect_uri=http://localhost:4200/#/home"
    };
    const formBody = Object.keys(details)
                           .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(details[key])}`)
                           .join('&');
    var id="l4cdqg69524auqlckjcifr44p"
    return this.http.post<any>("https://demoquicktest.auth.eu-west-2.amazoncognito.com/oauth2/token",
      formBody, {
        responseType: 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Basic ' + btoa(`${id}`)
          })
        });
  }

  // public logoutUserFromCognito(): Observable<any> {
  //   return this.http.get<any>(environment.logout);
  // }

  ngOnInit(): void {
    this.routerQuery.select(routerData => routerData.state).subscribe(state => {
      window.scrollTo(0, 0);
      const path = state.url.split('/');
      var url= state.url.split('/').pop();
     this.currentUrl=url
      if (path.length > 2) {
        this.activeSection = path[2];
        if (path.length > 3) {
          this.activePage = path[3].split('?')[0];
        } else {
          this.activePage = path.reverse()[0];
        }
      }

    });
    if(this.geneSysService.tokenType=="aws"){
      this.apiService.getUserdetails().subscribe(res=>{
        console.log(res);
        this.UserDataList=res
        if(this.UserDataList.firstCorporateLogin==true){
          localStorage.removeItem("UserCred");
          this.cookie.remove("startDate")
          this.cookie.remove("endDate");
          localStorage.removeItem("tabBrowserService");
          localStorage.removeItem("tabBrowser")
          this.userQuery.logout();
        }
      if(res){
      this.apiService.userPlanDetails().subscribe(res => {
        if (res) {
          this.memberShipPlan=res.membershipName
          this.userQuery.setCurrentPlan(res);
        }
  
        if (!res || res.id === 0 || res.id === '0') {
          this.router.navigateByUrl('/home/membership');
        }
      });
      this.apiService.getRoleListByUsername().subscribe(res=>{
        console.log(res)
        this.userQuery.setRole(res.role);
        this.userQuery.getRole().subscribe((user: any) => {
          console.log(user);
          if(user){
          this.readAccess=user.readAccess;
          this.writeAccess=user.writeAccess;
          this.fullAccess=user.fullAccess;
          }
        });
      })
    }
    })
      // active listner for plan changes. So we can update the feature availability
      this.userQuery.currentPlan().pipe(
        switchMap(res => {
          return this.apiService.getTestTypeForUser();
        })
      ).subscribe((res: any) => {
        this.availableFeatures.AutomaticTesting = res.AutomaticTesting;
        this.availableFeatures.LoadTesting = res.LoadTesting;
        this.availableFeatures.ScheduledTesting = res.ScheduledTesting;
      });
    }
   else{
    // this.apiService.userPlanDetails().subscribe(res => {
    //   if (res) {
    //     this.memberShipPlan=res.membershipName
    //     //this.userQuery.setCurrentPlan(res);
    //   }

    //   if (!res || res.id === 0 || res.id === '0') {
    //     this.router.navigateByUrl('/home/membership');
    //   }
    // });

    // this.apiService.getTestTypeForUser().subscribe((res: any) => {
    //   this.availableFeatures.AutomaticTesting = res.AutomaticTesting;
    //   this.availableFeatures.LoadTesting = res.LoadTesting;
    //   this.availableFeatures.ScheduledTesting = res.ScheduledTesting;
    // });
   }
    // this.myBrowser();
  }
  selectMenu(route){
    // '/home/'+route;
    console.log('/home/'+route)
      window.open('#/home/'+route)
      localStorage.setItem("tabBrowserService",'/home/'+route)
  }
  selectSubMenu(route,subroute){
    window.open('#/home/'+route+'/'+subroute)
    localStorage.setItem("tabBrowserService",'/home/'+route+'/'+subroute)
  }
  selectSubMenu1(route,subroute){
    window.open('#/home/'+route+'/'+subroute)
    localStorage.setItem("tabBrowserService",'/home/'+route+'/'+subroute)
  }
  loadProfileImg() {
    console.log('hello')
    this.apiService.getProfileImg().subscribe(res => {
      console.log(res)
      if (res) {
        if(res.size==0){
          this.thumbnail ="../../assets/dummy-profile-pic.jpg"
          this.userQuery.setUserImg(this.thumbnail);
        }
        else{
          var unsafeImageUrl = URL.createObjectURL(res);
          this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
          // var unsafeImageUrl = URL.createObjectURL(res.blob());
          // this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
          if(this.geneSysService.tokenType=='genesys'){
            this.geneSysService.setUserImg(res);
          }
          else{
            this.userQuery.setUserImg(res);
          }
           
        }
        
      }
      console.log('000')
    }, err => {
      this.thumbnail ="../../assets/dummy-profile-pic.jpg"
      if(this.geneSysService.tokenType=='genesys'){
        this.geneSysService.setUserImg(this.thumbnail);
      }
      else{
        this.userQuery.setUserImg(this.thumbnail);
      }
      console.log(err)
      console.log('---')
    });
  }

  toggleSidePanel() {
    this.viewDOM.nativeElement.classList.toggle('wide');
  }

  userMenuToggler() {
    this.showUserMenu = !this.showUserMenu;
  }

  navTo(url) {
    this.userMenuToggler();
    this.router.navigateByUrl(url);
  }

  logout() {
    localStorage.removeItem("UserCred");
    this.cookie.remove("startDate")
    this.cookie.remove("endDate");
    localStorage.removeItem("tabBrowserService");
    localStorage.removeItem("tabBrowser")
    this.cookie.remove("startDate")
    this.cookie.remove("SamlUser")
    this.userQuery.logout();
  }
  closeNav(){
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("menuHeaderpanel").style.width = "100%";
    //document.getElementById("allMainPage").style.width = "100%";
    //this.settingWidth="routerletwidth"
    this.settingWidth=false
  }
  openNav(){
    document.getElementById("mySidenav").style.width = "300px";
    this.settingWidth=true
    document.getElementById("menuHeaderpanel").style.width = "80%";
    //this.settingWidth="opennavigationrouterwidth"
  }
  openTestHistory(){
    this.router.navigateByUrl('/home/settings/login-data/login-history');
  }
}
