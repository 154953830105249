import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-forgot-account',
  templateUrl: './forgot-account.component.html',
  styleUrls: ['./forgot-account.component.scss']
})
export class ForgotAccountComponent implements OnInit {

  recoverForm: FormGroup;

  constructor(private router: Router) {
    this.recoverForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    })
  }

  ngOnInit(): void {
  }

  recover(event) {
    event.preventDefault();

    if (this.recoverForm.invalid) {
      return;
    }
    const params = this.recoverForm.value;

    localStorage.setItem("email", params.email)
    Auth.forgotPassword(params.email.toString()).then(res => {
      this.router.navigateByUrl('/recover/verify?user=' + params.email.toString());
    }, err => {

    });
  }

}
