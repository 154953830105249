
<app-page-container [title]="data?'Create Sub Folder':'Create Folder'">
    <div body class="create-sprint-container animate fade-in" style="padding: 20px;">
        <div body class="create-sprint-form">
          <form [formGroup]="createFolder">
            <app-form-input [required]="true" [label]="'Folder Name'" [error]="createFolder.controls.folderName">
                <input type="text" formControlName="folderName" (change)="isDirty=true">
            </app-form-input>
            <app-form-select [required]="true" class="item" [label]="'Select channel'" [error]="createFolder.controls.channelId">
                <select formControlName="channelId" (change)="isDirty=true">
                    <option value="">Select</option>
                    <option *ngFor="let item of channelsList" [value]="item.id">
                    {{ item.name }}</option>
                </select>
            </app-form-select>
        </form>
        </div>
     
      <div class="btn-set">
        <app-button class="btn"  [size]="'sm'" (click)="closeDialog()">Cancel
        </app-button>
        <app-button class="btn" [size]="'sm'" (click)="create()" [ngClass]="{showloading1:btnDisabled==false,hideloading1:btnDisabled==true}">
            Save</app-button>
            <app-button *ngIf="btnDisabled==true" [outline]="true" class="btn" [size]="'sm'" [ngClass]="{showloading1:btnDisabled==true,hideloading1:btnDisabled==false}">
              <mat-progress-spinner style="margin-left: 20px;" [diameter]="30" class="mat-spinner-color" mode="indeterminate">
              </mat-progress-spinner>
  </app-button>
      </div>
    </div>
    
    </app-page-container>
    