<!-- <script src="https://sdk-cdn.mypurecloud.com/javascript/latest/purecloud-platform-client-v2.min.js"></script>
    <script src="https://sdk-cdn.mypurecloud.com/client-apps/1.3.0/purecloud-client-app-sdk.js"></script>
    
    <script src="https://code.jquery.com/jquery-3.3.1.min.js"></script> -->

    <div class="login-container" *ngIf="!dataService">
        <div class="col s-100 panel-left">
            <div class="banner">
                <img class="bg" src="assets/illustrations/signup-banner.png">
            </div>
            <div class="jumbotron">
                <div class="box-wrapper">
                    <div class="box">
                        <div class="heading">Benefits of QuickTest</div>
                        <div class="s-line"></div>
                        <div class="list">
                            <div class="item"><img src="/assets/icons/check-round.svg">Review data on Dashboard</div>
                            <div class="item"><img src="/assets/icons/check-round.svg">Perform Load Testing</div>
                            <div class="item"><img src="/assets/icons/check-round.svg">Scheduled Test Performance</div>
                            <div class="item"><img src="/assets/icons/check-round.svg">Automatic Testing</div>
                            <div class="item"><img src="/assets/icons/check-round.svg">IVR Testing</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="brand">
                <img src="assets/brand-logo-light.png">
            </div>
        </div>
        <div class="col s-100 panel-right">
            <div class="form-wrapper animate fade-in" *ngIf="!showOTP">
                <app-heading [title]="'Sign In'"></app-heading>
                <form class="form" style="padding-bottom:2px;" (submit)="signIn()" [formGroup]="loginForm" (keydown.enter)="onEnterKeyDown()">
                    <app-alert *ngIf="showError" [type]="'danger'">
                        <div content>{{showError}}</div>
                    </app-alert>
                    <app-alert *ngIf="showSuccess">
                        <div content>You will be redirected shortly...</div>
                    </app-alert>
                    <app-form-input [label]="'Email'" [error]="loginForm.controls.email">
                        <input type="email" formControlName="email" autocomplete="off">
                    </app-form-input>
                    <app-form-input [label]="'Password'" [error]="loginForm.controls.password">
                        <input type="password" formControlName="password" autocomplete="off">
                    </app-form-input>
                    <div class="common-form-input" #panelDOM>
                        <div class="label" ></div> 
                        <input type="text"formControlName="nextPage" placeholder="Search module" 
                         [matAutocomplete]="auto" (keyup)="search($event)"
                          >
                          <div class="suffix" >
                            <div suffix  style="padding-top: 32px;">
                                <img src="assets/icons/search.svg"></div>
                        </div>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="changeRoute($event.option.value)">
                            <mat-option *ngFor="let module of moduleListFiltred" [value]="module.name">
                                {{ module.name }}
                            </mat-option>
                        </mat-autocomplete>
                        
                    </div>
                    <div class="forgot-user">
                        <div>
                            <!-- <app-form-checkbox [value]="loginForm.value.remember" (change)="setRemember($event)"
                                [label]="'Remember Me'">
                            </app-form-checkbox> -->
                        </div>
                        <a routerLink="/recover">Forgot Password?</a>
                    </div>
                    <div class="btn">
                        <app-button>Sign In</app-button>
                    </div>
                </form>
                <div class="btn" style="padding-bottom: 35px;padding-top: 0px;">
                    <app-button (click)="login()">Login with corporate ID</app-button>
                </div>
                <div class="login-link">
                    Don't have an account? <a routerLink="/signup">Sign Up</a>
                </div>
            </div>
            <div style="color: #888888;bottom: 0;position: fixed;padding: 28px;padding-top: 0px;">
                This system is for the use of authorised users only in accordance with QuickTest security policies and procedures.
                 Individuals using this device without authorisation or in excess of their authority are subject to sanctioning procedures by QuickTest authorities and/or law enforcement officials.
                  QuickTest will not be responsible for any misuse or personal use of any kind, in its information systems, and reserves the right for monitoring systems usage to control abusive situations or security policy violations.
            </div>
            <div class="form-wrapper animate fade-in" *ngIf="showOTP">
                <app-heading [title]="'OTP Verification'" style="text-align: center;"></app-heading>
            <form class="btn" [formGroup]="loginForm" (submit)="codeVeryfy()" (keydown.enter)="onEnterKeyDownOtp()">
                <app-form-input [label]="' Please enter the code we sent to your phone number'" style="width: 191px;">
                    <input type="text" formControlName="code">
                </app-form-input>
                <div class="btn">
                    <app-button [ngClass]="{showloading1:!btnDisplay,hideloading1:btnDisplay}">Continue</app-button>
                    <app-button [outline]="true" color="warn" [size]="'sm'" [ngClass]="{showloading1:btnDisplay,hideloading1:!btnDisplay}">
                        <mat-progress-spinner style="margin-left: 20px;" [diameter]="30" class="mat-spinner-color-danger" mode="indeterminate">
                        </mat-progress-spinner>
                    </app-button>
                </div>
            </form>
            <div class="resend-otp" (click)="signIn()">
                <a>Resend OTP</a>
            </div>
            </div>
        </div>
    </div>
    <!-- <script src="../purecloud/scripts/main.js"></script> -->
    <!-- <iframe [src]="loginUrl" width="500" height="600" frameborder="0"></iframe> -->
