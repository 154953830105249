import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { requestsService } from 'src/app/services/requests/requests.service';

@Component({
  selector: 'app-create-script-folder',
  templateUrl: './create-script-folder.component.html',
  styleUrls: ['./create-script-folder.component.scss']
})
export class CreateScriptFolderComponent implements OnInit {
  createFolder: FormGroup;
  isDirty = false;
  id: any;
  parentFolderId: any;
  btnDisabled: boolean = false;
  canDeactivate(): boolean {
    return !this.isDirty
  }
  channelsList = []
  constructor(
    public dialogRef: MatDialogRef<CreateScriptFolderComponent>,
    private router: Router,
    private automatorApi: requestsService,
    private toastr: ToastrService, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data)
    this.createFolder = new FormGroup({
      folderName: new FormControl('', [Validators.required]),
      channelId: new FormControl('', [Validators.required])
    });
  }
  ngOnInit() {
    // console.log(this.id),
    // console.log(this.parentFolderId)
    // this.id=this.data.id,
    // console.log(this.id)
    // this.parentFolderId=this.data.val
    if (this.data) {
      this.createFolder.controls.folderName.patchValue(this.data.folderName)
      this.createFolder.controls.channelId.patchValue(this.data.channelId)
    }
    this.automatorApi.getChannels().subscribe(res => {
      console.log(res)
      this.channelsList = res
    });
  }

  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control); // Recursive call for nested form groups
      } else {
        control.markAsTouched(); // Mark individual form controls as touched
      }
    });
  }
 
  create() {
    console.log(this.data);
    this.isDirty = false;
    if (Object.values(this.createFolder.controls).some(control => control.invalid) === true) {
      this.toastr.warning('Form is incomplete');
      this.markFormGroupAsTouched(this.createFolder)
      this.btnDisabled = false;
      return;
    }
    this.btnDisabled = true;
    if (this.data) {
      this.automatorApi.addScriptFolder({
        'folderName': this.createFolder.value.folderName,
        'channelId': Number(this.createFolder.value.channelId),
        'id': 0,
        'parentFolderId': this.data.id
      })
        .subscribe(res => {
          this.dialogRef.close(true);
          this.toastr.success(
            "Sub Folder created successfully",
          );
          this.btnDisabled = false;
        }, err => {
          if (err.message == 500) {
            this.toastr.error("Sub Folder with same name already exist");
            this.btnDisabled = false;

          } else {
            this.toastr.error('Error while creating Sub Folder');
            this.btnDisabled = false;
          }
        });
    } else {
      this.automatorApi.addScriptFolder({
        'folderName': this.createFolder.value.folderName,
        'channelId': Number(this.createFolder.value.channelId),
      })
        .subscribe(res => {
          this.dialogRef.close(true);
          this.toastr.success(
            "Folder created successfully",
          );
          this.btnDisabled = false;
        }, err => {
          if (err.message == 500) {
            this.toastr.error("Folder with same name already exist");
            this.btnDisabled = false;

          } else {
            this.toastr.error('Error while creating folder');
            this.btnDisabled = false;
          }
        });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }


}